<!-- 班级 -->
<template>
  <div class="box">
    <el-card class="card-f">
      <div class="search">
        <span>筛选小组</span>
        <el-select
          v-model="gradeValue"
          placeholder="请选择年级"
          size="mini"
          v-fence="{ data: '', functions: ['班级搜索框'] }"
        >
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select v-model="subjectValue" placeholder="请选择学科" size="mini">
          <el-option
            v-for="item in subjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="join-button">
          <el-button size="mini" type="primary" @click="headOperation(0)"
            >查询</el-button
          >
          <el-button size="mini" @click="headOperation(1)">重置</el-button>
          <el-button
            type="primary"
            size="mini"
            @click="classOperation(0)"
            v-fence="{ data: '', functions: ['创建班级', '成人-创建班级'] }"
            >创建小组</el-button
          >
          <!-- 成人教育-创建班级 -->
          <el-button
            @click="classOperation(1)"
            type="primary"
            size="mini"
            v-fence="{ data: '', functions: ['加入班级', '成人-加入班级'] }"
            >加入小组</el-button
          >
        </div>
      </div>
      <el-empty description="暂无小组!" v-if="classData.length == 0"></el-empty>
      <el-scrollbar style="height: 100%">
        <el-card class="room-card">
          <p class="calss-title" v-if="classData.length !== 0">小组列表</p>
          <el-card
            shadow="hover"
            v-for="(item, index) in classData"
            :key="index"
            style="margin-bottom: 10px"
          >
            <div
              @click="operation(0, item)"
              style="width: 100%; height: 100%; position: relative"
              v-if="status == 1 || status == 0"
            >
              <p>{{ item.className }}</p>
              <span>老师：{{ item.teacher || "暂无数据" }}</span>
              <span>简介：{{ item.remark || "暂无数据" }}</span>
              <span>学生：{{ item.studentCount || "暂无数据" }}</span>
              <!-- <div
                :id="`roomChart${index}`"
                :style="{ width: '80%', height: '100%' }"
                class="roomChart"
              ></div>-->
            </div>
            <div
              @click="operation(0, item)"
              style="
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
              "
              v-fence="{
                data: '',
                functions: ['成人-学生活动列表', '成人-老师活动列表'],
              }"
            >
              <div>
                <img src="../../assets/img/bianzu2.png" alt />
              </div>
              <div style="width: 80%; padding-left: 10px">
                <p>{{ item.className }}</p>
                <span style="margin-bottom: 5px; display: inline-block"
                  >科目: {{ item.subjectName || "暂无数据" }}</span
                >
                <span style="width: 100%"
                  >开班时间：{{ item.creatorTime || "暂无数据" }}</span
                >
                <span style="width: 100%"
                  >结班时间：{{ item.finishTime || "暂无数据" }}</span
                >
              </div>
              <div style="width: 20%">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="督促提醒"
                  placement="bottom"
                >
                  <i
                    @click.stop="nudge(1, item)"
                    class="el-icon-bell"
                    style="font-size: 24px; margin-right: 10px"
                  ></i>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="答疑"
                  placement="bottom"
                >
                  <i
                    @click.stop="nudge(2, item)"
                    class="el-icon-question"
                    style="font-size: 24px"
                  ></i>
                </el-tooltip>
              </div>
            </div>
            <div class="button-div">
              <el-button
                size="mini"
                @click="operation(1, item)"
                v-fence="{ data: '', functions: ['编辑班级', '成人-编辑班级'] }"
                >编辑</el-button
              >
              <el-button
                size="mini"
                @click="operation(2, item)"
                v-fence="{ data: '', functions: ['创建群组'] }"
                >群组</el-button
              >
              <el-button size="mini" @click="operation(0, item)"
                >进入</el-button
              >
              <el-button
                size="mini"
                @click="operation(3, item)"
                v-fence="{ data: '', functions: ['删除班级', '成人-删除班级'] }"
                icon="el-icon-delete"
              ></el-button>
            </div>
          </el-card>
        </el-card>
      </el-scrollbar>
      <el-pagination
        background
        layout="prev, pager, next, sizes, jumper"
        :total="classRoomTotal"
        :current-page="classRoomCurrent"
        :page-size="pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        @current-change="classRoomData"
        @size-change="handleSizeChange"
      ></el-pagination>
    </el-card>
    <!-- 创建班级 -->
    <el-dialog
      title="创建小组"
      :visible.sync="showCreateClassRoom"
      width="50%"
      :before-close="handleClose"
      class="room-select"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="小组名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入小组名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="科目" prop="region">
          <el-select
            v-model="ruleForm.region"
            :placeholder="'请选择科目'"
          >
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结束时间" required v-if="role_type == 1">
          <el-col :span="11">
            <el-form-item prop="date_value1">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.date_value1"
                style="width: 100%"
                :picker-options="pickerOptions0"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2" style="text-align: center">-</el-col>
          <el-col :span="11">
            <el-form-item prop="date_value2">
              <el-time-picker
                placeholder="选择时间"
                v-model="ruleForm.date_value2"
                style="width: 100%"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="类型" prop="radio_type" v-if="role_type == 1">
          <el-radio-group v-model="ruleForm.radio_type">
            <el-radio :label="1">集中式</el-radio>
            <el-radio :label="2">自主式</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年级" prop="grade" v-if="role_type == 0">
          <el-select v-model="ruleForm.grade" placeholder="请选择年级">
            <el-option
              v-for="item in gradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小组简介">
          <el-input
            type="textarea"
            v-model="ruleForm.classRoomContent"
            placeholder="请输入小组简介"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCreateClassRoom = false">取 消</el-button>
        <el-button type="primary" @click="createRoom('ruleForm')"
          >创 建</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑班级 -->
    <el-dialog
      title="编辑小组"
      :visible.sync="showEditingClassroom"
      width="900px"
      :before-close="handleClose"
      class="edit-room-select"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="小组标题" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="'科目'" prop="region">
          <el-select
            v-model="ruleForm.region"
            :placeholder="'请选择科目'"
          >
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <span>小组代号：{{ editData.code }}</span>
        <el-form-item label="日期范围" required v-if="role_type == 1">
          <el-col :span="11">
            <el-form-item prop="date_value1">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="ruleForm.date_value1"
                style="width: 100%"
                :picker-options="pickerOptions0"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2" style="text-align: center">-</el-col>
          <el-col :span="11">
            <el-form-item prop="date_value2">
              <el-time-picker
                placeholder="选择时间"
                v-model="ruleForm.date_value2"
                style="width: 100%"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="年级" prop="grade" v-if="role_type == 0">
          <el-select v-model="ruleForm.grade" placeholder="请选择年级">
            <el-option
              v-for="item in gradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小组简介">
          <el-input
            type="textarea"
            v-model="ruleForm.classRoomContent"
            placeholder="请输入小组简介"
          ></el-input>
        </el-form-item>
        <div>
          <el-button
            class="addStudent"
            @click="addOperation(1, '学生')"
            v-if="isk12"
            >添加学生</el-button
          >
          <el-button
            class="addTeacher"
            @click="addOperation(0, '老师')"
            v-if="isk12"
            >添加老师</el-button
          >
          <el-button
            class="addTeacher"
            style="margin-left: 100px"
            @click="addOperation(0, '老师')"
            v-if="!isk12"
            >添加用户</el-button
          >
          <el-button
            v-if="isk12"
            class="addTeacher"
            @click="removeOperation(1)"
            type="danger"
            plain
            >移除学生</el-button
          >
          <el-button
            v-if="isk12"
            class="addTeacher"
            @click="removeOperation(2)"
            type="danger"
            plain
            >移除老师</el-button
          >
          <el-button
            v-if="!isk12"
            class="addTeacher"
            @click="removeOperation(2)"
            type="danger"
            plain
            >移除用户</el-button
          >
          <el-upload
            :action="url"
            :http-request="importExcel"
            list-type="text"
            :show-file-list="false"
            accept=".xls, .xlsx"
            style="display: inline-block; margin-left: 10px"
          >
            <el-button type="primary">导入学生</el-button>
          </el-upload>
          <el-select
            v-model="rankingTableType"
            placeholder="是否展示学生积分"
            style="display: inline-block; margin-left: 10px; width: 160px"
            v-fence="{ data: '', functions: ['控制学生积分表显示'] }"
          >
            <el-option
              v-for="item in integralOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditingClassroom = false">取 消</el-button>
        <el-button type="primary" @click="editingRoom('ruleForm')"
          >编 辑</el-button
        >
      </span>
    </el-dialog>
    <!-- 创建群组 -->
    <el-dialog title="管理群组" :visible.sync="showGroup" width="680px">
      <el-table :data="groupData">
        <el-table-column
          property="name"
          label="名称"
          width="150"
        ></el-table-column>
        <el-table-column
          property="list.length"
          label="人数"
          width="150"
        ></el-table-column>
        <el-table-column label="名单" width="150">
          <template slot-scope="scoped">
            <el-popover placement="right" width="180" trigger="click">
              <el-table :data="scoped.row.list" height="400">
                <el-table-column
                  width="150"
                  property="fullName"
                  label="姓名"
                ></el-table-column>
              </el-table>
              <el-button slot="reference" size="mini">名单</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template scope="scope">
            <el-button
              slot="reference"
              @click="addEditGroup(scope.row)"
              size="mini"
              style="margin-right: 5px"
              >编辑</el-button
            >
            <el-popover
              placement="top"
              width="160"
              v-model="scope.row.showButton"
            >
              <p>您确定删除此群组吗?</p>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="scope.row.showButton = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="deleteGroup(scope.row)"
                  >确定</el-button
                >
              </div>
              <el-button slot="reference" size="mini">删除</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="addClass" v-if="showAdd">
        <span class="el-icon-circle-plus-outline" @click="addOtherGroup"></span>
      </div>
      <el-dialog
        width="670px"
        :visible.sync="innerGroup"
        append-to-body
        class="group"
      >
        <el-form
          label-width="80px"
          :model="groupRuleForm"
          :rules="groupRules"
          ref="groupRuleForm"
        >
          <el-form-item label="群组名称" prop="groupName">
            <el-input v-model="groupRuleForm.groupName"></el-input>
          </el-form-item>
        </el-form>
        <el-transfer v-model="transferGroup" :data="data"></el-transfer>
        <div class="group-button">
          <el-button
            type="primary"
            @click="moreGroup('groupRuleForm')"
            v-if="!showEdit"
            >添加</el-button
          >
          <el-button
            type="primary"
            @click="editGroup('groupRuleForm')"
            v-if="showEdit"
            >编辑</el-button
          >
          <el-button @click="innerGroup = false">取消</el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <!-- 移除学生 -->
    <el-dialog
      title="移除学生"
      :visible.sync="showRemoveBtn"
      width="670px"
      :before-close="handleClose"
    >
      <el-transfer
        v-model="removeStudentValue"
        :data="removeStudentData"
      ></el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showRemoveBtn = false">取 消</el-button>
        <el-button type="primary" @click="determineRemove(0)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 移除老师 -->
    <el-dialog
      title="移除老师"
      :visible.sync="showTeacherRemoveBtn"
      width="670px"
      :before-close="handleClose"
    >
      <el-transfer
        v-model="removeTeacherValue"
        :data="removeTeacherData"
      ></el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showTeacherRemoveBtn = false">取 消</el-button>
        <el-button type="primary" @click="determineRemove(1)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  classList,
  subjectList,
  removeClass,
  addClass,
  updateClass,
  addGroup,
  groupList,
  GetStudentByGradeClass,
  removeGroup,
  updateGroup,
  addUser,
  UserIntoClassByCode,
  activeList,
  userExitClass,
  addSsoByStudent,
  UserIntoClassByCodes,
} from "../../api/api";
import XLSX from "xlsx";
import moment from "moment";
export default {
  data() {
    return {
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      // pickerOptions1: {
      //   selectableRange: null
      // },
      classRoomTotal: 0,
      classRoomCurrent: 1,
      pagesize: 10,
      subjectOptions: this.parameter.subject(),
      ruleForm: {
        name: "",
        region: "",
        classRoomContent: "",
        grade: "",
        date_value1: "",
        date_value2: "",
        radio_type: "",
      },
      radio_type: 1,
      rules: {
        name: [
          { required: true, message: "请输入小组名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        region: [{ required: true, message: "请选择科目", trigger: "change" }],
        grade: [{ required: true, message: "请选择年级", trigger: "change" }],
        classRoomContent: [
          { required: true, message: "请输入小组简介", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        date_value1: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date_value2: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        radio_type: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
      },
      pieOptions: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}",
        },
        legend: {
          top: "30%",
          right: "10%",
          orient: "vertical",
          icon: "rect",
          itemWidth: 20,
          itemHeight: 20,
          data: ["已完成", "未完成"],
        },
        series: [
          {
            name: "任务状态",
            type: "pie",
            radius: ["45%", "65%"],
            data: [
              { value: 0, name: "已完成" },
              { value: 0, name: "未完成" },
            ],
          },
        ],
      },
      gradeValue: null,
      gradeOptions: this.parameter.grade(),
      subjectValue: null,
      showCreateClassRoom: false,
      showEditingClassroom: false,
      classData: [],
      editData: {},
      showGroup: false,
      groupData: [],
      showAdd: false,
      innerGroup: false,
      showRemoveBtn: false,
      showTeacherRemoveBtn: false,
      removeStudentValue: [],
      removeTeacherValue: [],
      groupRuleForm: {
        id: "",
        groupId: "",
        groupName: "",
        classId: "",
      },
      groupRules: {
        groupName: [
          { required: true, message: "请输入群组名称", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
      },
      transferGroup: [],
      showEdit: false,
      data: [],
      removeStudentData: [],
      removeTeacherData: [],
      studentData: [],
      appIdUser: null,
      isk12: true,
      before_checkout: null, //校验之前的数据
      url: "",
      studentsData: [],
      rankingTableType: 1, //默认显示全班积分
      integralOptions: [
        {
          //老师控制是否显示全班积分
          value: 1,
          label: "显示全班积分",
        },
        {
          value: 2,
          label: "显示个人积分",
        },
        {
          value: 0,
          label: "不显示",
        },
      ],
      status: 0, //0  老师  1  学生  -1 其他系统
      role_type: -1, //角色类型  0 K12老师  1 成人教育老师
    };
  },
  created() {},
  components: {},
  mounted() {
    this.loadSubject();
    this.loadClass();
    let userId = Number(this.utils.userId());
    _hmt.push(["_trackEvent", "教室", "查看", "全部教室", userId]);
    this.analysis();
  },
  methods: {
    // 成人--加载学科
    loadSubject() {
      subjectList({}).then((res) => {
        if (res.code == 0) {
          res.data.list.map((item, index) => {
            item.value = item.id;
            item.label = item.name;
          });
          this.subjectOptions = res.data.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 选择分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.loadClass();
    },
    //
    nudge(val, item) {
      //督促1  答疑2
      if (val == 1) {
        if (this.utils.identity("成人-学生活动列表")) {
          this.$router.push({
            path: "/urge",
            query: {
              classId: item.id,
              subjectName: item.subjectName,
              subjects: item.subjects,
            },
          });
        } else {
          this.$router.push({
            path: "/teacherurge",
            query: {
              classId: item.id,
              subjectName: item.subjectName,
              subjects: item.subjects,
            },
          });
        }
      } else {
        if (this.utils.identity("成人-学生活动列表")) {
          this.$router.push({
            path: "/questions",
            query: {
              classId: item.id,
              subjectName: item.subjectName,
              subjects: item.subjects,
            },
          });
        } else {
          this.$router.push({
            path: "/teacherquestions",
            query: {
              classId: item.id,
              subjectName: item.subjectName,
              subjects: item.subjects,
            },
          });
        }
      }
    },
    // 解析appIds
    analysis() {
      if (this.utils.identity("K12做题") || this.utils.identity("IMX做题")) {
        this.status = 1;
      }
      if (this.utils.identity("创建班级")) {
        this.role_type = 0;
      } else if (this.utils.identity("成人-创建班级")) {
        this.role_type = 1;
      }
      if (
        this.utils.identity("成人-学生活动列表") ||
        this.utils.identity("成人-老师活动列表")
      ) {
        this.status = -1;
      }
    },
    // 解析excel表格
    importExcel(content) {
      this.studentsData = [];
      const file = content.file;
      // let file = file.files[0] // 使用传统的input方法需要加上这一步
      const types = file.name.split(".")[1];
      const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
        (item) => item === types
      );
      if (!fileType) {
        this.$message("格式错误！请重新选择");
        return;
      }
      this.file2Xce(file).then((tabJson) => {
        if (tabJson && tabJson.length > 0) {
          this.xlsxJson = tabJson;
          this.fileList = this.xlsxJson[0].sheet;
          let n = "匹配的字段";
          this.fileList.forEach((item, index, arr) => {
            if (item[n] === this.name) {
              this.studentsData.push(item["用户名"]);
            }
          });
        }
      });
      // 最后拿到的
      // this.addUser(this.studentsData, 1)
      this.batchAdd(this.studentsData);
    },
    file2Xce(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: "binary",
          });
          const result = [];
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName]),
            });
          });
          resolve(result);
        };
        // reader.readAsBinaryString(file.raw)
        reader.readAsBinaryString(file); // 传统input方法
      });
    },
    // 批量导入接口
    batchAdd(arr) {
      setTimeout(() => {
        addSsoByStudent({ userNames: arr }).then((res) => {
          if (res.code == 0) {
            UserIntoClassByCodes({
              code: this.editData.code,
              userNames: arr,
            }).then((res) => {
              if (res.code == 0) {
                this.$message.success("添加成功！");
              } else {
                this.$message.error(res.data + res.message);
              }
            });
          } else {
            this.$message.error(
              res.message + "(" + res.data + ")" + ",请修改文件后重试！"
            );
          }
        });
      }, 500);
    },
    // 班级分页
    classRoomData(val) {
      this.classRoomCurrent = val;
      this.loadClass();
    },
    // 动态加载环形图
    loadRoomChart(val, index) {
      this.roomChart = this.$echarts.init(
        document.getElementById(`roomChart${index}`)
      );
      var option;
      option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: ["25%", "40%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "12",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: val.completed,
                name: "已完成",
                itemStyle: {
                  normal: { color: "#488AFF" },
                  emphasis: { color: "#488AFF" },
                },
              },
              {
                value: val.task,
                name: "未完成",
                itemStyle: {
                  normal: { color: "#EFF3F5" },
                  emphasis: { color: "#EFF3F5" },
                },
              },
            ],
          },
        ],
      };
      this.roomChart.setOption(option);
    },
    // 确认移除学生和老师
    determineRemove(val) {
      if (val == 0) {
        let param = {
          classId: this.editData.id,
          user: this.removeStudentValue,
          isUser: 1,
        };
        userExitClass(param).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "移除成功!",
              type: "success",
            });
            this.showRemoveBtn = false;
            this.loadClass();
            this.removeStudentValue = [];
          } else {
            this.$message.error(res.message);
          }
        });
      } else if (val == 1) {
        let param = {
          classId: this.editData.id,
          user: this.removeTeacherValue,
          isUser: 0,
        };
        userExitClass(param).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "移除成功!",
              type: "success",
            });
            this.showTeacherRemoveBtn = false;
            this.loadClass();
            this.removeTeacherValue = [];
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 移除操作组
    removeOperation(type) {
      // 1 移除学生  2 移除老师
      if (type == 1) {
        this.removeStudentValue = [];
        this.loadOperation(1, this.editData.id);
        this.showRemoveBtn = true;
      } else if (type == 2) {
        this.removeTeacherValue = [];
        this.loadOperation(0, this.editData.id);
        this.showTeacherRemoveBtn = true;
      }
    },
    // 头部操作组
    headOperation(type) {
      // 0 搜索  1 重置
      if (type == 0) {
        this.classRoomCurrent = 1;
        this.loadClass();
      } else if (type == 1) {
        this.subjectValue = null;
        this.gradeValue = null;
        this.loadClass();
      }
    },
    // 添加老师学生
    addOperation(type, name) {
      // 0 添加老师  1 添加学生
      this.$prompt(`请输入${name}名称`, `添加${name}`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputPlaceholder: `多名${name}请按' , '分割,如:账号1,账号2,账号3`,
        inputErrorMessage: "输入不能为空",
      })
        .then(({ value }) => {
          if (value) {
            let arr = value.split("，");
            let val = arr.join(",");
            arr = val.split(",");
            arr = [...new Set(arr)];
            this.addUser(arr, type);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 添加学生和老师接口
    addUser(arr, type) {
      arr.forEach((item, index) => {
        if (item !== "") {
          // type == 1  学生
          // type == 0  老师
          if (type == 1) {
            var param = {
              userName: item,
            };
          } else if (type == 0) {
            var param = {
              userName: item,
              classId: this.editData.id,
            };
          }
          addUser(param).then((res) => {
            if (res.code == 1005) {
              this.$message.error(res.message);
            } else {
              UserIntoClassByCode({
                code: this.editData.code,
                userName: item,
              }).then((res) => {
                if (res.code == 0) {
                  if (index + 1 == arr.length) {
                    this.$message({
                      type: "success",
                      message: "添加成功!",
                    });
                    this.loadClass();
                  }
                } else {
                  this.$message.error(item + res.message);
                }
              });
            }
          });
        } else {
          this.$message.error("输入的用户中存在格式错误");
        }
      });
    },
    // 打开编辑群组
    addEditGroup(val) {
      val.list.map((item) => {
        item["key"] = item.id;
        item["label"] = item.fullName;
      });
      this.data = this.studentData;
      this.showEdit = true;
      this.innerGroup = true;
      this.groupRuleForm.groupName = val.name;
      this.groupRuleForm.id = val.id;
      this.groupRuleForm.classId = val.classId;
      let arr = [];
      val.list.map((item) => {
        arr.push(item.id);
      });
      this.transferGroup = arr;
      this.editTransferGroup = arr;
    },
    // 编辑完成群组
    editGroup(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {
            groupId: this.groupRuleForm.id,
            name: this.groupRuleForm.groupName,
            user: this.transferGroup,
          };
          updateGroup(param).then((res) => {
            this.innerGroup = false;
            this.loadGroupList(this.editData.id);
            this.$message({
              message: "编辑成功！",
              type: "success",
            });
          });
        } else {
          return false;
        }
      });
    },
    // 新增群组
    moreGroup(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = [];
          this.transferGroup.map((item) => {
            let obj = {};
            obj["id"] = item;
            arr.push(obj);
          });
          let param = {
            name: this.groupRuleForm.groupName,
            classId: this.editData.id,
            list: arr,
          };
          addGroup(param).then((res) => {
            if (res.code == 0) {
              this.innerGroup = false;
              this.groupRuleForm.groupName = "";
              this.loadGroupList(this.editData.id);
              this.$message({
                message: "添加成功！",
                type: "success",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取当前班级下的学生or老师
    loadOperation(type, val) {
      // 0 老师  1 学生
      var isUser = type;
      if (this.utils.identity("添加标签用户")) {
        isUser = 2;
      }
      let param = {};
      if (type == 0) {
        param = { classId: val, isUser: isUser, userId: this.appIdUser };
      } else if (type == 1) {
        param = { classId: val, isUser: isUser };
      }
      GetStudentByGradeClass(param).then((res) => {
        if (res.code == 0) {
          res.data.list.map((item) => {
            item["key"] = item.id;
            item["label"] = item.fullName;
          });
          if (type == 0) {
            this.removeTeacherData = res.data.list;
          } else if (type == 1) {
            this.removeStudentData = res.data.list;
            this.studentData = res.data.list;
            this.data = this.studentData;
          }
        }
      });
    },
    // 添加群组
    addOtherGroup() {
      this.groupRuleForm.groupName = "";
      this.showEdit = false;
      this.innerGroup = true;
      this.data = this.studentData;
      this.transferGroup = [];
    },
    // 加载小组
    loadGroupList(val) {
      groupList({ classId: val, pageNum: null, pageSize: null }).then((res) => {
        res.data.list.map((item) => {
          item["label"] = item.name + "(" + item.list.length + "人)";
          item["key"] = item.id;
        });
        this.groupData = res.data.list;
        this.showAdd = true;
      });
    },
    // 删除小组
    deleteGroup(val) {
      let arr = [val.id];
      removeGroup(arr).then((res) => {
        if (res.code == 0) {
          this.loadGroupList(this.editData.id);
          this.$message({
            message: "删除成功！",
            type: "success",
          });
        }
      });
    },
    // 创建or加入班级
    classOperation(type) {
      // 0 创建  1 加入
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
      if (type == 0) {
        this.ruleForm = {
          name: "",
          region: "",
          classRoomContent: "",
          grade: "",
        };
        this.showCreateClassRoom = true;
      } else if (type == 1) {
        this.$prompt("请输入小组编码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: "",
          inputErrorMessage: "编码格式不正确",
        })
          .then(({ value }) => {
            UserIntoClassByCode({ code: value }).then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "加入小组成功!",
                });
              } else if (res.code == 1) {
                this.$message.error("加入失败!小组编码错误!");
              } else {
                this.$message({
                  type: "success",
                  message: res.message,
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      }
    },
    // 操作按钮组
    operation(type, val) {
      // 0 进入  1 编辑  2 群组  3 删除
      if (type == 0) {
        if (
          this.utils.identity("成人-创建班级") ||
          this.utils.identity("成人-加入班级")
        ) {
          console.log(val);
          let dis_click = 0; //允许点击  1 不允许点击
          if (
            this.currentTime.getTimestamp(this.currentTime.currentTime(1)) >
            this.currentTime.getTimestamp(val.finishTime)
          ) {
            dis_click = 1;
          }
          this.$router.push({
            path: "/adultActivityList",
            query: { classId: val.id, type: val.type, dis_click: dis_click },
          });
        } else {
          this.$router.push({ path: "/activity", query: { classId: val.id } });
        }
      } else if (type == 1) {
        if (this.utils.identity("添加标签用户")) {
          this.isk12 = false;
        }
        this.before_checkout = val;
        let time = this.currentTime.formatDate(val.finishTime);
        this.ruleForm = {
          name: val.className,
          region: val.subjects,
          classRoomContent: val.remark,
          grade: val.grade,
          teacher: "",
          date_value1: time,
          date_value2: time,
        };
        this.editData = val;
        this.rankingTableType = Number(val.rankingTableType);
        this.showEditingClassroom = true;
      } else if (type == 2) {
        this.editData = val;
        this.showGroup = true;
        this.loadGroupList(val.id);
        this.loadOperation(1, val.id);
      } else if (type == 3) {
        this.$confirm("此操作将永久删除该小组, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            activeList({ classId: val.id }).then((res) => {
              if (res.data.list.length == 0) {
                let param = [];
                param.push(val.id);
                removeClass(param).then((res) => {
                  if (res.code == 0) {
                    this.$message({
                      message: "删除成功！",
                      type: "success",
                    });
                    this.loadClass();
                  } else {
                    this.$message.error(res.message);
                  }
                });
              } else {
                this.$message({
                  message: "该小组下存在活动,不可删除!",
                  type: "warning",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    // 加载班级
    loadClass() {
      let param = {
        // info: 1,
        grade: this.gradeValue,
        subjects: this.subjectValue,
        pageNum: this.classRoomCurrent,
        pageSize: this.pagesize,
      };
      classList(param).then((res) => {
        res.data.list.map((item, index) => {
          if (item.teacherName) {
            item.teacherName = item.teacherName.join(",");
          }
          if (item.students) {
            item.students = item.students + "人";
          }
          if (this.status == 1) {
            item["completed"] = item.studentCompletedCount;
            item["task"] = item.studentTaskCount;
          } else {
            item["completed"] = item.teacherCompletedCount;
            item["task"] = item.teacherTaskCount;
          }
          item.creatorTime = this.currentTime.formatDate(
            item.creatorTime * 1000
          );
          item.finishTime = this.currentTime.formatDate(item.finishTime * 1000);
          let obj = this.subjectOptions.find((find_item) => {
            return item.subjects == find_item.id;
          });
          if (obj) {
            item.curriculum = obj.name;
          }
          // setTimeout(() => {
          //   this.loadRoomChart(item, index)
          // }, 300);
        });
        this.classRoomTotal = res.data.total;
        this.classData = res.data.list;
      });
    },
    // 编辑完成
    editingRoom(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.date_value1) {
            var time1 = moment(this.ruleForm.date_value1).format(
              "YYYY/MM/DD HH:MM:SS"
            );
            var date = new Date(this.ruleForm.date_value2);
            var date_value =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate() +
              " " +
              date.getHours() +
              ":" +
              date.getMinutes() +
              ":" +
              date.getSeconds();
          }
          let time = this.currentTime.getTimestamp(
            time1.split(" ")[0] + " " + date_value.split(" ")[1]
          );
          let param = {
            id: this.editData.id,
            className: this.ruleForm.name,
            grade: this.ruleForm.grade,
            subjects: this.ruleForm.region,
            remark: this.ruleForm.classRoomContent,
            rankingTableType: this.rankingTableType,
            finishTime: time,
          };
          if (
            this.ruleForm.grade !== this.before_checkout.grade ||
            this.ruleForm.region !== this.before_checkout.subjects
          ) {
            this.$confirm(
              "此操作可能会影响后续的活动布置等行为, 是否继续?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.updateClass(param);
              })
              .catch(() => {});
          } else {
            this.updateClass(param);
          }
        } else {
          return false;
        }
      });
    },
    // 编辑班级接口
    updateClass(param) {
      updateClass(param).then((res) => {
        this.loadClass();
        this.ruleForm = {
          name: "",
          region: "",
          classRoomContent: "",
          grade: "",
          // teacher: ''
        };
        this.showEditingClassroom = false;
        this.$message({
          message: "编辑小组成功！",
          type: "success",
        });
      });
    },
    // 创建班级
    createRoom(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let time = null;
          if (this.ruleForm.date_value1) {
            var time1 = moment(this.ruleForm.date_value1).format(
              "YYYY/MM/DD HH:MM:SS"
            );
            var date = new Date(this.ruleForm.date_value2);
            var date_value =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate() +
              " " +
              date.getHours() +
              ":" +
              date.getMinutes() +
              ":" +
              date.getSeconds();
            time = this.currentTime.getTimestamp(
              time1.split(" ")[0] + " " + date_value.split(" ")[1]
            );
          }
          let param = {
            className: this.ruleForm.name,
            grade: this.ruleForm.grade ? this.ruleForm.grade : 0,
            subjects: this.ruleForm.region,
            remark: this.ruleForm.classRoomContent,
            rankingTableType: 1, //默认显示全部排名
            finishTime: time,
            type: this.ruleForm.radio_type,
          };
          addClass(param).then((res) => {
            if (res.code == 0) {
              this.showCreateClassRoom = false;
              this.loadClass();
              this.$message({
                message: "创建小组成功！",
                type: "success",
              });
            } else {
              this.$message.error(res.message);
            }
          });
          this.$refs[formName].resetFields();
        } else {
          return false;
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang='less' scoped>
@import "./style/index.less";
@import "../../style/mouse.less";
</style>
